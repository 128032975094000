import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { FlexLayout, FlexChild } from '@xureilab/tiny-components';
import Styled from 'styled-components';

function PostLink(props) {
    const post = props.post;
    const path = post.frontmatter.path || `/posts/${post.id}`;
    return (
        <li>
            ({post.frontmatter.date})
            {' '}
            <a href={path}>{post.frontmatter.title}</a>
        </li>
    );
}

function organizePosts(allposts) {
    const out = {
        tinyComponents: [],
        other: [],
    };
    
    allposts.forEach(edge => {
        if (edge.node.frontmatter.path) {
            if (edge.node.frontmatter.path.indexOf('/tiny-components/') !== -1) {
                out.tinyComponents.push(edge);
                return;
            }
        }
        
        out.other.push(edge);
    });
    
    return out;
}

function Blog(props) {
    console.log(props);
    const edges = props.data.allMarkdownRemark.edges;
    
    const allposts = edges
    .filter(edge => !!edge.node.frontmatter.date);
    
    const posts = organizePosts(allposts);
    
    const tinyComponentsPosts = posts.tinyComponents.map(edge => <PostLink key={edge.node.id} post={edge.node}/>);
    const otherPosts = posts.other.map(edge => <PostLink key={edge.node.id} post={edge.node}/>);
    
    return (
        <main className={props.className}>
            <Helmet>
                <title>Blog | Xureilab</title>
            </Helmet>
            
            <div style={{position: 'relative'}}>
                <div className="logo">
                    <img src="/images/logo-dark.png" alt="xureiLab"/>
                    <span style={{fontSize: '2.4rem', marginLeft: 32, top: -1, position: 'relative'}}>Blog</span>
                </div>
                
                <div style={{padding: '80px 0'}}>
                    <FlexLayout>
                        <FlexChild grow={1} style={{width: 1}}>
                            <div style={{padding: '0 15px'}}>
                                <h2>Tiny Components</h2>
                                <ul>
                                    {tinyComponentsPosts}
                                </ul>
                            </div>
                        </FlexChild>
                        {/*<FlexChild grow={1} style={{width: 1}}>*/}
                        {/*    <div>*/}
                        {/*        <h3>Ideas</h3>*/}
                        {/*        {posts}*/}
                        {/*    </div>*/}
                        {/*</FlexChild>*/}
                        <FlexChild grow={1} style={{width: 1}}>
                            <div style={{padding: '0 15px'}}>
                                <h2>Other</h2>
                                <ul>
                                    {otherPosts}
                                </ul>
                            </div>
                        </FlexChild>
                    </FlexLayout>
                    <br/>
                </div>
            </div>
        </main>
    );
}

//language=SCSS
Blog = Styled(Blog)`
& {
    .logo {
        > * {
            vertical-align: baseline;
        }
    }
    ul {
        margin: 0;
        padding: 0;
    }
}
`;

export const pageQuery = graphql`
    query {
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        path
                        title
                    }
                }
            }
        }
    }
`;
export default Blog;
